document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.notification-container > .notification')].forEach(
    notificationElement => {
      setTimeout(() => {
        if (notificationElement.parentNode) {
          notificationElement.parentNode.removeChild(notificationElement);
        }
      }, 5 * 1000);

      notificationElement
        .querySelector('.notification-close-button')
        .addEventListener('click', () => {
          notificationElement.parentNode.removeChild(notificationElement);
        });
    }
  );
});
